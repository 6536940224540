import { useEffect, useState } from "react";
import { Moon, Sun, MobileMenu, CloseMenu } from "./Svgs";
import { NavLink, useLocation } from "react-router-dom";
import styles from "../modules/menu.module.scss";
import { motion, AnimatePresence } from "framer-motion";

const mobilenavVariants = {
  initial: { opacity: 0, y: "10vh" },
  animate: { opacity: 1, y: 0 },
  exit: { y: "-10vh", opacity: 0 },
};

const iconVariants = {
  initial: { scale: 0 },
  animate: { scale: 1 },
  transition: { duration: 0.3, ease: "easeIn" },
};

const links = [
  { to: "/", name: "Home" },
  { to: "/stack", name: "Stack" },
  { to: "projects", name: "Projects" },
];

export default function Menu(props) {
  const location = useLocation();
  const [dark, setDark] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  function nightMode() {
    setDark(!dark);
    document.querySelector("html").classList.add("dark");
  }

  function dayMode() {
    setDark(!dark);
    document.querySelector("html").classList.remove("dark");
  }

  function toggleMobileNav() {
    setIsOpen(!isOpen);
  }

  return (
    <div className="fixed left-0 top-0 w-full bg-indigo-950/10 dark:bg-slate-950/40">
      <div className="flex p-6 items-center backdrop-blur border-b border-slate-300 dark:border-slate-700">
        <motion.h1
          initial={{ filter: "blur(12px)" }}
          animate={{ filter: "blur(0)" }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
          className="text-2xl font-semibold md:absolute md:left-6"
        >
          Joel Ymele Leki
        </motion.h1>
        <div className="flex-1"></div>

        <ul className="hidden md:flex gap-3">
          {links.map((item, index) => (
            <CustomLink to={item.to} name={item.name} key={index} />
          ))}
        </ul>
        <div className="flex-1"></div>

        {dark && (
          <motion.button
            initial="initial"
            animate="animate"
            transition={"transiton"}
            variants={iconVariants}
            className={styles.sunmoon}
            onClick={dayMode}
          >
            <Sun />
          </motion.button>
        )}

        {!dark && (
          <motion.button
            initial="initial"
            animate="animate"
            transition={"transiton"}
            variants={iconVariants}
            className={styles.sunmoon}
            onClick={nightMode}
          >
            <Moon />
          </motion.button>
        )}

        {!isOpen && (
          <motion.button
            initial="initial"
            animate="animate"
            transition={"transiton"}
            variants={iconVariants}
            className={styles.mobilemenu}
            onClick={toggleMobileNav}
          >
            <MobileMenu />
          </motion.button>
        )}
        {isOpen && (
          <motion.button
            initial="initial"
            animate="animate"
            transition={"transiton"}
            variants={iconVariants}
            className={styles.mobilemenu}
            onClick={toggleMobileNav}
          >
            <CloseMenu />
          </motion.button>
        )}
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial="initial"
            animate="animate"
            exit={"exit"}
            variants={mobilenavVariants}
            className="h-screen backdrop-blur flex flex-col items-center md:hidden"
          >
            <ul className="text-center space-y-8 mt-52 ">
              {links.map((item, index) => (
                <CustomLink to={item.to} name={item.name} key={index} />
              ))}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

function CustomLink({ to, name }) {
  return (
    <li>
      <NavLink
        className={({ isActive }) => (isActive ? styles.active : styles.link)}
        to={to}
      >
        {name}
      </NavLink>
    </li>
  );
}
