import { db } from "../../components/Firebase";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import Project from "../../components/Project";

export default function Projects(props) {
  const [projects, setProjects] = useState([]);

  async function getProjects() {
    const list = [];
    const querySnapshot = await getDocs(collection(db, "projects"));
    querySnapshot.forEach((doc) => {
      list.push({ id: doc.id, ...doc.data() });
    });
    setProjects(list);
  }

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <>
      <div className="mt-28 max-w-6xl mx-auto">
        {/* <h2 className="">Projects</h2> */}
        {/* <hr className="h2Underline" /> */}
        {projects.map((project) => (
          <Project key={project.id} item={project} />
        ))}
      </div>
    </>
  );
}
