import { useAnimate, stagger, motion } from "framer-motion";
import { useEffect } from "react";

function TechDisp({ img, name, level }) {
  return (
    <>
      <motion.div initial={{ opacity: 0 }} className="tech flex items-center">
        <img
          src={"./images/stack/" + img}
          loading="lazy"
          alt="Javascipt"
          className="mr-1 h-[80px] w-[80px]"
        />
        <div>
          <p>{name}</p>
          <span className="text-slate-400 text-sm -mt-1 block">{level}</span>
        </div>
      </motion.div>
    </>
  );
}

export default function TechList({ title, stack, level }) {
  const [scope, animate] = useAnimate();

  useEffect(() => {
    animate(
      ".tech",
      { opacity: 1 },
      {
        duration: 0.2,
        delay: stagger(0.2),
        ease: "easeInOut",
      }
    );
  });

  return (
    <>
      <motion.div
        className="bg-white shadow-sm m-2 dark:bg-indigo-950 rounded-lg p-4 "
        ref={scope}
      >
        <h2 className="text-center text-2xl mb-4 p-2">{title}</h2>
        <div className="grid grid-cols-2">
          {stack.map((item, index) => (
            <TechDisp
              img={item.img}
              name={item.name}
              level={level.en[item.level]}
              key={index}
            />
          ))}
        </div>
      </motion.div>
    </>
  );
}
