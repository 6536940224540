import TechList from "./techdisp";
import stack from "./stack.json";

export default function Stack() {
  return (
    <>
      <div className="mt-28 md:max-w-6xl mx-auto grid md:grid-cols-2 gap-4">
        <TechList title="Frontend" stack={stack.frontend} level={stack.level} />
        <TechList title="Backend" stack={stack.backend} level={stack.level} />
        <TechList title="Services" stack={stack.services} level={stack.level} />
        <TechList title="Others" stack={stack.other} level={stack.level} />
      </div>
    </>
  );
}
