import { Suspense } from "react";
import { motion } from "framer-motion";
import {
  DesktopIcons,
  DownloadIcon,
  MobileIcon,
  TabletIcon,
} from "../../components/Icons";

export default function Home() {
  return (
    <div className="min-h-screen w-full md:w-full md:h-auto flex flex-col items-center justify-center bg-auto bg-center border-b dark:border-slate-700 ">
      <Suspense>
        <motion.img
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
          loading="lazy"
          src="./images/joelymele.jpeg"
          alt="Joel Ymele Leki"
          className="rounded-full bg-blurprofile bg-cover w-[200px] h-[200px] ring-2 ring-[#00000000] border-4 border-spacing-3 border-primary shadow-xl dark:shadow-none"
        />
      </Suspense>
      <motion.h1
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5, ease: "easeOut", duration: 0.5 }}
        className="text-4xl font-bold mt-8 text-transparent bg-clip-text bg-gradient-to-r from-sky-400 to-fuchsia-600"
      >
        Full Stack Developer
      </motion.h1>
      <motion.h3
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.8, duration: 0.5, ease: "easeIn" }}
        className="text-xl text-slate-500"
      >
        Joel Ymele Leki
      </motion.h3>
      <motion.p
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.1, duration: 0.5, ease: "easeIn" }}
        className="text-center max-w-sm text-lg mx-auto mt-6"
      >
        Developer with experience creating websites as well as progressive web
        apps and native apps using web technology.
      </motion.p>

      <div className="flex items-baseline gap-4 justify-center mt-4">
        <MobileIcon className="w-8 h-8 text-fuchsia-400" />
        <TabletIcon className="w-12 h-12 text-orange-400" />
        <DesktopIcons className="w-16 h-16 text-teal-400" />
      </div>
      <div className="transition duration-150 mt-8 hover:scale-105 active:scale-95">
        <motion.a
          // initial={{
          //   filter: blur(4)
          // }}
          href="./assets/resume.pdf"
          download
          className="bg-primary rounded-lg shadow-xl shadow-primary/50 text-base px-8 py-4 text-slate-200 font-medium dark:shadow-none dark:ring-1 dark:ring-blue-700"
        >
          <DownloadIcon className="w-6 h-6 inline mr-2" />
          Resume
        </motion.a>
      </div>
    </div>
  );
}
