import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyBzCkQ1bFOuMbb91jIuItphZZpbB6I1ZBo",
    authDomain: "portfolio-f446c.firebaseapp.com",
    projectId: "portfolio-f446c",
    storageBucket: "portfolio-f446c.appspot.com",
    messagingSenderId: "125235237304",
    appId: "1:125235237304:web:ffe5200caba032cf123f4d",
    measurementId: "G-QSFEVL42ML"
};



export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
