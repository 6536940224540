import styles from '../modules/project.module.scss'

export default function Project({item}) {
    return (
        <div className={styles.project}>
            <div>
                <img src={item.image} alt="Screenshot of pylbiocheglab" />
            </div>
            <div className="p-2">
                { item.link &&
                    <a className="transition duration-200 p-2 flex items-center hover:ring-2 ring-slate-200 rounded-md"  href={item.link} target="_blank" rel="noreferrer">
                        <h3 className="flex-1 text-xl font-semibold">{item.name}</h3>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                <path fillRule="evenodd" d="M19.902 4.098a3.75 3.75 0 00-5.304 0l-4.5 4.5a3.75 3.75 0 001.035 6.037.75.75 0 01-.646 1.353 5.25 5.25 0 01-1.449-8.45l4.5-4.5a5.25 5.25 0 117.424 7.424l-1.757 1.757a.75.75 0 11-1.06-1.06l1.757-1.757a3.75 3.75 0 000-5.304zm-7.389 4.267a.75.75 0 011-.353 5.25 5.25 0 011.449 8.45l-4.5 4.5a5.25 5.25 0 11-7.424-7.424l1.757-1.757a.75.75 0 111.06 1.06l-1.757 1.757a3.75 3.75 0 105.304 5.304l4.5-4.5a3.75 3.75 0 00-1.035-6.037.75.75 0 01-.354-1z" clipRule="evenodd" />
                            </svg>
                        </span>
                    </a>
                }
                { !item.link &&
                    <div className="p-2">
                        <h3 className="flex-1 text-xl font-semibold">{item.name}</h3>
                    </div>
                }
                <p className="p-2">{item.desc}</p>
                { item.tasks &&
                <ul className="list-disc pl-6 leading-6 p-2 -mt-2">
                    { item.tasks.map((task, index) => <li key={index}>{task}</li>)}
                </ul>
            }
            </div>
        </div>
    )
}